import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'pages',
        title: 'Pages',
        type: 'group',
        icon: 'pages',
        role: '1',
        children: [
            {
                id: 'dashboards', title: 'Dashboard', translate: 'Dashboard', type: 'item', icon: 'dashboard',  url: '/pages/dashboards/project'
                // children: [{ id: 'project', title: 'Project', translate: 'Project', type: 'item', url: '/pages/dashboards/project' },
                // ]
            },
            { id: 'company-management', title: 'Company', type: 'item', icon: 'business', url: '/pages/accounts/providers' },
            // { id: 'Users', title: 'Contacts', type: 'item', icon: 'group', url: '/pages/users' },
            { id: 'customers', title: 'Contacts', type: 'item', icon: 'people', url: '/pages/customers' },
            { id: 'Payout', title: 'Payout Transactions', type: 'item', icon: 'payment', url: '/pages/payout-report' },
            // { id: 'methods', title: 'Methods', translate: 'Methods', type: 'collapsable',  icon: 'view_list', 
            // children: [ 
            // { id: 'API-Methods', title: 'API Methods', type: 'item', icon: '', url: '/pages/api-methods',},
            // { id: 'Version-Methods', title: 'Version Methods', type: 'item', icon: '', url: '/pages/version-method',},
            // { id: 'Company-Version', title: 'Company Version', type: 'item', icon: '', url: '/pages/company-version',},
            // ]},
            {
                id: 'manage-transactions', title: 'Manage Transactions',
                type: 'item', icon: 'transfer_within_a_station',
                url: '/pages/manage-transactions'
            },
            {
                id: 'reports', title: 'Reports', translate: 'Reports', type: 'collapsable', icon: 'grid_on',
                children: [
                    // {id: 'transaction-report', title: 'Transaction Report', translate: 'Transaction Report', type: 'item', url: 'pages/reports/transaction-report'},
                    { id: 'api-log-report', title: 'api log Report', translate: 'API Logs', type: 'item', url: 'pages/reports/api-log-report' },
                    //  {id: 'top-x-customer-report', title: 'Top X Customer Report', translate: 'Top X Customer Report', type: 'item', url: 'pages/reports/top-x-customer-report'}
                    { id: 'customer-balance-report', title: 'Customer Balance', translate: 'Customer Balance', type: 'item', url: 'pages/reports/customer-balance-report' },
                    { id: 'ach-transaction-report', title: 'ACH Transactions', translate: 'ACH Transactions', type: 'item', url: 'pages/reports/ach-transaction-report' },
                    { id: 'ewallet-fees', title: 'eWallet Fees', type: 'item', url: 'pages/reports/ewallet-fees'},
                    { id: 'duplicate-transaction-report', title: 'Duplicate Transaction Report', type: 'item', url: 'pages/reports/duplicate-transaction-report'},
                    { id: 'ordered-card-report', title: 'Ordered Card Report', type: 'item', url: 'pages/reports/ordered-card-report'},
                    { id: 'suspicious-transaction-report', title: 'Suspicious Transaction Report', type: 'item', url: 'pages/reports/suspicious-transaction-report'}
                ]
            },
            {
                id: 'Settings', title: 'Settings', translate: 'Settings', type: 'collapsable', icon: 'settings',
                children: [
                    { id: 'Settings Category', title: 'Settings Category', type: 'item', icon: '', url: '/pages/settings-category' },
                    { id: 'Settings', title: 'Settings', type: 'item', icon: '', url: '/pages/settings' },
                    { id: 'Company Setting', title: 'Company Setting', type: 'item', icon: '', url: '/pages/company-setting' },
                    { id: 'Fee Settings', title: 'Fee Settings', type: 'item', icon: '', url: '/pages/fee-settings' }
                ]
            }
        ]
    },

    {
        id: 'pages',
        title: 'Pages',
        type: 'group',
        icon: 'pages',
        role: '2',
        children: [
            {
                id: 'dashboards', title: 'Dashboard', translate: 'Dashboard', type: 'item', icon: 'dashboard', url: '/pages/dashboards/project'
                // children: [{ id: 'project', title: 'Project', translate: 'Project', type: 'item', url: '/pages/dashboards/project' },
                // ]
            },
            { id: 'customers', title: 'Contacts', type: 'item', icon: 'people', url: '/pages/customers' },
            // { id: 'Users', title: 'Users', type: 'item', icon: 'group', url: '/pages/users', },
            //  { id: 'manage-transctions', title: 'Manage Transactions',
            //    type: 'item', icon: 'transfer_within_a_station',
            //   url: '/pages/manage-transctions'},

            {id: 'transactions', title: 'Transactions', translate: 'Transactions', type: 'collapsable', icon: 'transform',
            children: [
                { id: 'point-transactions', title: 'Point Transactions', type: 'item', url: '/pages/point-transactions' },
                { id: 'import-transactions', title: 'Import Transactions', type: 'item', url: '/pages/import' },
                { id: 'import-past-transactions', title: 'Import Past Transactions', type: 'item', url: '/pages/import-past-transactions'},
                { id: 'Payout', title: 'Payout Transactions', type: 'item', url: '/pages/payout-report' },
                { id: 'rellevate-transactions', title: 'Rellevate Transactions', type: 'item', url: '/pages/rellevate-transactions' },
            ]
            },
            
            {
                id: 'reports', title: 'Reports', translate: 'Reports', type: 'collapsable', icon: 'grid_on',
                children: [
                    // {id: 'transaction-report', title: 'Transaction Report', translate: 'Transaction Report', type: 'item', url: 'pages/reports/transaction-report'},
                    { id: 'api-log-report', title: 'api log Report', translate: 'API Logs', type: 'item', url: 'pages/reports/api-log-report' },
                    { id: 'Schedule-transaction-report', title: 'Schedule Transaction Report', translate: ' Scheduled Transactions', type: 'item', url: 'pages/reports/company-report' },
                    { id: 'customer-balance-report', title: 'Customer Balance', translate: 'Customer Balance', type: 'item', url: 'pages/reports/customer-balance-report' },
                    // {id: 'top-x-customer-report', title: 'Top X Customer Report', translate: 'Top X Customer Report', type: 'item', url: 'pages/reports/top-x-customer-report'}
                    //{ id: 'ewallet-fees', title: 'eWallet Fees', type: 'item', url: 'pages/reports/ewallet-fees'}
                    { id: 'inactive-user-report', title: 'Inactive User Report', type: 'item', url: 'pages/reports/inactive-users'},
                    { id: 'block-report', title: 'Block Report', type: 'item', url: 'pages/reports/block-report'},
                    // { id: 'commission-payout-report', title: 'Commission Payout Report', type: 'item', url: 'pages/reports/commission-payout-report'},
                    { id: 'bulk-commission-payout-report', title: 'Bulk Commission Payout', type: 'item', url: 'pages/reports/bulk-commission-payout-report'},
                    // { id: 'company-setting-activity-log', title: 'Company Setting Activity Log', type: 'item', url: 'pages/reports/company-setting-activity-log'},
                    { id: 'tax-status-report', title: 'Tax Status Report', type: 'item', url: 'pages/reports/tax-status-report'},
                    { id: 'dormancy-report', title: 'Dormancy Report', type: 'item', url: 'pages/reports/dormancy-report'}
                ]
            },
            {
                id: 'manage', title: 'Manage', translate: 'Manage', type: 'collapsable', icon: 'widgets',
                children: [
                    { id: 'Users', title: 'Users', type: 'item', url: '/pages/users', },
                    { id: 'company-point-accounts', title: 'Wallet Types', type: 'item', url: '/pages/point-accounts' },
                ]
            },
            {
                id: 'Settings', title: 'Settings', translate: 'Settings', type: 'collapsable', icon: 'settings',
                children: [
                    { id: 'Company Setting', title: 'Company Setting', type: 'item', icon: '', url: '/pages/company-setting' }
                ]
            }

        ]
    },

    {
        id: 'pages',
        title: 'Pages',
        type: 'group',
        icon: 'pages',
        role: '3',
        children: [
            {
                id: 'dashboards', title: 'Dashboard', translate: 'Dashboard', type: 'item', icon: 'dashboard', url: '/pages/dashboards/project'
            },
            { id: 'customers', title: 'Contacts', type: 'item', isPageEditable: true, icon: 'people', url: '/pages/customers' },
            { id: 'impersonation', title: 'Impersonation', type: 'none', url: ''},
            { id: 'manual-adjustment', title: 'Manual Adjustment', type: 'none', url: ''},
            {id: 'transactions', title: 'Transactions', translate: 'Transactions', type: 'collapsable', icon: 'transform',
            children: [
                { id: 'point-transactions', title: 'Point Transactions', type: 'item', isPageEditable: true, url: '/pages/point-transactions' },
                { id: 'import-transactions', title: 'Import Transactions', type: 'item', isPageEditable: true, url: '/pages/import' },
                { id: 'import-past-transactions', title: 'Import Past Transactions', type: 'item', url: '/pages/import-past-transactions'},
                { id: 'Payout', title: 'Payout Transactions', type: 'item', isPageEditable: true, url: '/pages/payout-report' },
            ]
            },
            
            {
                id: 'reports', title: 'Reports', translate: 'Reports', type: 'collapsable', icon: 'grid_on',
                children: [
                    { id: 'api-log-report', title: 'api log Report', translate: 'API Logs', type: 'item', url: 'pages/reports/api-log-report' },
                    { id: 'Schedule-transaction-report', title: 'Schedule Transaction Report', translate: ' Scheduled Transactions', type: 'item', isPageEditable: true, url: 'pages/reports/company-report' },
                    { id: 'customer-balance-report', title: 'Customer Balance', translate: 'Customer Balance', type: 'item', isPageEditable: true, url: 'pages/reports/customer-balance-report' },
                    { id: 'tax-status-report', title: 'Tax Status Report', type: 'item', url: 'pages/reports/tax-status-report'},
                    //{ id: 'ewallet-fees', title: 'eWallet Fees', type: 'item', url: 'pages/reports/ewallet-fees'}
                    { id: 'inactive-user-report', title: 'Inactive User Report', type: 'item', url: 'pages/reports/inactive-users'},
		            { id: 'block-report', title: 'Block Report', type: 'item',isPageEditable: true, url: 'pages/reports/block-report'},
                    { id: 'bulk-commission-payout-report', title: 'Bulk Commission Payout', isPageEditable: true, type: 'item', url: 'pages/reports/bulk-commission-payout-report'},
                    { id: 'dormancy-report', title: 'Dormancy Report', type: 'item', url: 'pages/reports/dormancy-report'}
                ]
            },
            {
                id: 'manage', title: 'Manage', translate: 'Manage', type: 'collapsable', icon: 'widgets',
                children: [
                    { id: 'Users', title: 'Users', type: 'item', isPageEditable: true, url: '/pages/users', },
                    { id: 'company-point-accounts', title: 'Wallet Types', type: 'item', isPageEditable: true, url: '/pages/point-accounts' }
                ]
            },
            {
                id: 'Settings', title: 'Settings', translate: 'Settings', type: 'collapsable', icon: 'settings',
                children: [
                    { id: 'Company Setting', title: 'Company Setting', type: 'item', isPageEditable: true, icon: '', url: '/pages/company-setting' }
                ]
            },
            {
                id: 'debitCardPortal', title: 'Debit Card Portal', translate: 'Debit Card Portal', type: 'collapsable', icon: 'payment',
                children : [
                    { id: 'Portal', title: 'Portal', type: 'item', icon: 'account_box',  url : "https://pm-uat.readycash.com/", externalUrl: true, openInNewTab: true},
                    { id: 'Virtual Debit Card', title: 'Virtual Debit Card', type: 'item', isPageEditable: true, icon: 'credit_card', url: '/pages/virtual-debit-card' }
                ]
            }

        ]
        // children: [
        //     //{id: 'dashboards', title: 'Dashboards',translate: 'Dashboards',type: 'collapsable', icon: 'dashboard',
        //     // children: [ {id: 'project', title: 'Project', translate: 'Project', type: 'item', url: '/pages/dashboards/project' },
        //     // ]},
        //     { id: 'customers', title: 'Contacts', type: 'item', icon: 'people', url: '/pages/customers' },
        //     { id: 'company-point-accounts', title: 'Point Accounts', type: 'item', icon: 'account_box', url: '/pages/point-accounts' },
        //     {id: 'transactions', title: 'Transactions', translate: 'Transactions', type: 'collapsable', icon: 'transform',
        //     children: [
        //         { id: 'point-transactions', title: 'Point Transactions', type: 'item', url: '/pages/point-transactions' },
        //         { id: 'import-transactions', title: 'Import Transactions', type: 'item', url: '/pages/import' },
        //         { id: 'Payout', title: 'Payout Transactions', type: 'item', url: '/pages/payout-report' },
        //     ]
        //     },
        //     // { id: 'Payout', title: 'Payout Transactions', type: 'item', icon: 'payment', url: '/pages/payout-report' },
        //     {
        //         id: 'reports', title: 'Reports', translate: 'Reports', type: 'collapsable', icon: 'grid_on',
        //         children: [
        //             //{id: 'transaction-report', title: 'Transaction Report', translate: 'Transaction Report', type: 'item', url: 'pages/reports/transaction-report'},
        //             { id: 'api-log-report', title: 'api log Report', translate: 'API Logs', type: 'item', url: 'pages/reports/api-log-report' },
        //             // {id: 'top-x-customer-report', title: 'Top X Customer Report', translate: 'Top X Customer Report', type: 'item', url: 'pages/reports/top-x-customer-report'}]
        //             { id: 'customer-balance-report', title: 'Customer Balance', translate: 'Customer Balance', type: 'item', url: 'pages/reports/customer-balance-report' },
        //         ]
        //     },
        // ]
    },
    {
        id: 'pages',
        title: 'Pages',
        type: 'group',
        icon: 'pages',
        role: '6',
        children: [
            {
                id: 'dashboards', title: 'Dashboard', translate: 'Dashboard', type: 'item', icon: 'dashboard', url: '/pages/dashboards/project'
                // children: [{ id: 'project', title: 'Project', translate: 'Project', type: 'item', url: '/pages/dashboards/project' },
                // ]
            },
            { id: 'subaccount', title: 'Subaccount', type: 'item', icon: 'people', url: '/pages/accounts/companies' },
            // { id: 'Users', title: 'Users', type: 'item', icon: 'group', url: '/pages/users', },
            //  { id: 'manage-transctions', title: 'Manage Transactions',
            //    type: 'item', icon: 'transfer_within_a_station',
            //   url: '/pages/manage-transctions'},

            {id: 'transactions', title: 'Transactions', translate: 'Transactions', type: 'collapsable', icon: 'transform',
            children: [
                { id: 'point-transactions', title: 'Point Transactions', type: 'item', url: '/pages/point-transactions' },
                { id: 'import-transactions', title: 'Import Transactions', type: 'item', url: '/pages/import' },
                { id: 'import-past-transactions', title: 'Import Past Transactions', type: 'item', url: '/pages/import-past-transactions'},
                { id: 'Payout', title: 'Payout Transactions', type: 'item', url: '/pages/payout-report' },
                { id: 'rellevate-transactions', title: 'Rellevate Transactions', type: 'item', url: '/pages/rellevate-transactions' },
            ]
            },

            {
                id: 'manage-transactions', title: 'Manage Transactions',
                type: 'item', icon: 'transfer_within_a_station',
                url: '/pages/manage-transactions'
            },
            {
                id: 'reports', title: 'Reports', translate: 'Reports', type: 'collapsable', icon: 'grid_on',
                children: [
                    // {id: 'transaction-report', title: 'Transaction Report', translate: 'Transaction Report', type: 'item', url: 'pages/reports/transaction-report'},
                    { id: 'api-log-report', title: 'api log Report', translate: 'API Logs', type: 'item', url: 'pages/reports/api-log-report' },
                    { id: 'Schedule-transaction-report', title: 'Schedule Transaction Report', translate: ' Scheduled Transactions', type: 'item', url: 'pages/reports/company-report' },
                    { id: 'customer-balance-report', title: 'Customer Balance', translate: 'Customer Balance', type: 'item', url: 'pages/reports/customer-balance-report' },
                    // {id: 'top-x-customer-report', title: 'Top X Customer Report', translate: 'Top X Customer Report', type: 'item', url: 'pages/reports/top-x-customer-report'}
                    { id: 'ach-transaction-report', title: 'ACH Transactions', translate: 'ACH Transactions', type: 'item', url: 'pages/reports/ach-transaction-report' },
                    { id: 'ewallet-fees', title: 'eWallet Fees', type: 'item', url: 'pages/reports/ewallet-fees'},
                    { id: 'inactive-user-report', title: 'Inactive User Report', type: 'item', url: 'pages/reports/inactive-users'},
                    { id: 'block-report', title: 'Block Report', type: 'item', url: 'pages/reports/block-report'},
                    { id: 'commission-payout-report', title: 'Commission Payout Report', type: 'item', url: 'pages/reports/commission-payout-report'},
                    { id: 'bulk-commission-payout-report', title: 'Bulk Commission Payout', type: 'item', url: 'pages/reports/bulk-commission-payout-report'},
                    // It is not move live yet
                    // { id: 'white-list-customer-report', title: 'Customer White List Report', type: 'item', url: 'pages/reports/customer-white-list-report'},
                    { id: 'company-setting-activity-log', title: 'Company Setting Activity Log', type: 'item', url: 'pages/reports/company-setting-activity-log'},
                    { id: 'sqs-report', title: 'SQS Report', type: 'item', url: 'pages/reports/sqs-report'}
                ]
            },
            {
                id: 'manage', title: 'Manage', translate: 'Manage', type: 'collapsable', icon: 'widgets',
                children: [
                    { id: 'Users', title: 'Users', type: 'item', url: '/pages/users', },
                    { id: 'company-point-accounts', title: 'Wallet Types', type: 'item', url: '/pages/point-accounts' },
                    // { id: 'company-bank-accounts', title: 'Bank Account', type: 'item', url: '/pages/bank-accounts' },
                ]
            },
            {
                id: 'Settings', title: 'Settings', translate: 'Settings', type: 'collapsable', icon: 'settings',
                children: [
                    // { id: 'Company Setting', title: 'Company Setting', type: 'item', icon: '', url: '/pages/company-setting' },
                    { id: 'Fee Settings', title: 'Fee Settings', type: 'item', icon: '', url: '/pages/fee-settings' }
                ]
            }

        ]
    }


];