import { Injectable } from '@angular/core';
import {RestService} from '../../../shared/rest.service';
import {AuthService}  from '../../../shared/auth.service';
import { API_URL} from '../../../constants';
import { MatTableDataSource } from '@angular/material/table';

@Injectable({
    providedIn: 'root'
})
export class CustomersService {
    public companyPageSize = 10;
    public companyPageNumber = 1;
    apiUrl: string = API_URL;
    public selectedCustomerid;
    public selectedCompanyid;
    public customerTransactionDetails = {};
    public accountTotalCount: Number = 0;
    public holdTotalCount:Number = 0;
    public holdTransactionDetails: MatTableDataSource<any>;
    public AccountTransactionList: MatTableDataSource<any>;
    public customerDetails = {
        firstName: '',
        lastName:'',
        externalCustomerID: 0,
        backofficeID: 0,
        createdDate: new Date(),
        maxTransferLimit: 0,
        companyID:"",
        id:''
    };
    public customerPointBalancesDetails = {
        activeAmount: 0,
        amount:0,
        holdAmount:0 
    };
    constructor(public _RestService: RestService, public _AuthService: AuthService, ) {

    }


    IsActiveCustomer(id,active) {
        let _url = "/api/Customer/IsActiveCustomer?id=" + id +'&active='+active;
        return this._RestService.Delete(_url);
    }
    EditCustomer(EditCustomerRequest) {
        var _url = "/Customer/UpdateCustomer";
        return this._RestService.Post(_url, EditCustomerRequest);
    }

    AddCustomer(customerRequest: any) {
        var _url = "/Customer/CreateCustomerDetails";
        return this._RestService.Post(_url, customerRequest);
    }
    SetCustomerStatus(customerRequest: any) {
        var _url = "/Customer/SetCustomerStatus";
        return this._RestService.Post(_url, customerRequest);
    }

    GetCustomerList(request: any) {
        var _url = '/Customer/GetCustomerList';
        return this._RestService.Post(_url, request);
    }
    importFile(data) {
        var _url = "/Customer/CustomerImport";
        return this._RestService.PostFile(_url, data);
    }
    GetCompanyPointDetail(request: any) {
        var _url = '/CompanyPointAccount/GetCompanyPointAccounts' + request;
        return this._RestService.Get(_url);
    }
    GetAllPointAccountTransactons(request) {
        var _url = "/CustomerPointTransactions/GetCustomerAllPointTransactions";
        return this._RestService.Post(_url, request);
    }
    CustomerAllTransactionsHistory(request) {
        var _url = "/CustomerPointTransactions/CustomerAllTransactionsHistory";
        return this._RestService.Post(_url, request);
    }
    GetPointAccountType() {
        var _url = '/PointAccountType/GetPointAccountType';
        return this._RestService.Get(_url);
    }
    GetCurrencycode() {
        var _url = '/v1/Currencies';
        return this._RestService.Get(_url);
    }
    AdjustCustomerBalance(request) {
        var _url = '/CustomerPointTransactions/AdjustPointAccountTransaction';
        return this._RestService.Post(_url, request);
    }
    IsCustomerExist(request)
    {
       
        var _url="/Customer/IsCustomerExist";
        return this._RestService.Post(_url,request);
    }
    SetCustomerMaxLimit(request) {
        var _url = '/Customer/SetCustomerMaxLimit';
        return this._RestService.Post(_url, request);
    }
    IsCustomerBlock(request) {
        var _url = `/Customer/IsCustomerBlock?companyID=${request.companyID}&pointAccountID=${request.pointAccountID}&customerID=${request.customerId}`;
        return this._RestService.Post(_url,null);
    }
    BlockCustomer(request) {
        var _url = `/Customer/BlockCustomer?companyID=${request.companyID}&pointAccountID=${request.pointAccountID}&externalCustomerId=${request.externalCustomerId}&customerId=${request.customerId}`;
        return this._RestService.Post(_url,null);
    }
    UnBlockCustomer(request) {
        var _url = `/api/Customer/UnBlockCustomer?companyId=${request.companyID}&customerId=${request.customerId}`;
        return this._RestService.Delete(_url);
    }
    GetAllLanguages() {
        var _url = `/Language/GetAllLanguages`;
        return this._RestService.Get(_url);
    }
    public Getcountries() {
        var _url = "/v1/Address/countries";
        return this._RestService.Get(_url);
    }
    public GetRegions(countryCode:string){
        var _url = `/v1/Address/${countryCode}/regions`;
        return this._RestService.Get(_url);
    }
    public GetRedeemType(){
        var _url = `/Utils/GetRedeemType`;
        return this._RestService.Get(_url);
    }
    public ValidateCustomerEmail(email,companyId){
        var _url = `/Customer/ValidateCustomerEmail?email=${email}&companyId=${companyId}`;
        return this._RestService.Get(_url);
    }
    public ImpersonateToCustomerPortal(req){
        var _url = `/users/ssotoken?customerId=${req.backofficeID}&companyId=${req.companyId}`;
        return this._RestService.Get(_url);
    }
    public syncCustomer(req){
        var _url = `/Customer/SyncCustomerDetails?externalCustomerID=${req.externalCustomerID}&companyId=${req.companyId}`;
        return this._RestService.Get(_url);
    }
    public checkTygaAccount(req){
        var _url = `/Tyga/CheckAccount?externalCustomerId=${req.externalCustomerID}&companyId=${req.companyId}`;
        return this._RestService.Get(_url);
    }
    public removeTygaAccount(req){
        var _url = `/Tyga/RemoveAccount?externalCustomerId=${req.externalCustomerId}&companyId=${req.companyID}`;
        return this._RestService.Post(_url,{});
    }
    ExportCustomerAllPointTransactions(request) {
        var _url = "/CustomerPointTransactions/ExportCustomerAllPointTransactions";
        return this._RestService.Postblob(_url, request);
    }
    SaveFile(blob, fileName) {
        return this._RestService.saveFile(blob, fileName);
    }
    public TriggerUnifyEvent(req){
        var _url = `/Customer/TriggerUnifyEvent?eventName=${req.eventName}&externalCustomerID=${req.externalCustomerID}&companyId=${req.companyId}`;
        return this._RestService.Post(_url,{});
    }
    ExportCustomerReport(request) {
        var _url =`/Customer/GetCustomerListExport?${request}`;
        return this._RestService.Getblob(_url);
    }
    getCustomerBatchList() {
        var _url =`/Customer/GetCustomerBatchList`;
        return this._RestService.Get(_url);
    }
    getCustomerBatchDetail(batchId) {
        var _url =`/Customer/GetCustomerBatchDetail?batchId=${batchId}`;
        return this._RestService.Get(_url);
    }
    getCustomerBatchListByStatus(status) {
        var _url =`/Customer/GetCustomerBatchListByStatus?status=${status}`;
        return this._RestService.Get(_url);
    }
    ExportCustomersWithBalance(request:any){
        let _url = `/Customer/ExportCustomersWithBalance?${request}`
        return this._RestService.Get(_url);
      }
}